import {
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

export const TableSkeleton: React.FC<{ rows: number; cols: number }> = ({
  rows,
  cols,
}) => {
  return (
    <TableContainer>
      <Table>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from({ length: cols }).map((_, colIndex) => (
              <TableCell key={colIndex}>
                <Skeleton variant="rectangular" width={100} height={50} />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  );
};
