import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getListing } from "store/listingDetailsSlice";
import apiManager from "utils/apiManager";

interface BulkActionsState {
  count: number | null;
  loading: boolean;
  status: { success: boolean; message: string } | null;
}

const initialState: BulkActionsState = {
  count: null,
  loading: false,
  status: null,
};

export const createPost = createAsyncThunk(
  "/create-post",
  async (req: FormData, thunkAPI) => {
    try {
      const response = await apiManager.post("/create-post", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create post"
      );
    }
  }
);

export const createLogoPost = createAsyncThunk(
  "/create-logo-post",
  async (
    { req, location_id }: { req: FormData; location_id: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-logo-post", req, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response) {
        const { dispatch } = thunkAPI;
        dispatch(getListing(location_id));
      }
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

export const locationUpdate = createAsyncThunk(
  "/location-update",
  async (
    req: {
      location_id: string;
      title: string;
      web_site: string;
      description: string;
      regularHours: any;
      specialHours?: any;
      openingDate: any;
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.put("/location-update", req);
      if (response) {
        const { dispatch } = thunkAPI;
        dispatch(getListing(req?.location_id));
      }
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Something went wrong!"
      );
    }
  }
);

const bulkActionsSlice = createSlice({
  name: "bulkActions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //create post
      .addCase(createPost.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(createPost.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      })
      //create logo post
      .addCase(createLogoPost.pending, (state) => {
        state.loading = true;
        state.status = null;
      })
      .addCase(createLogoPost.fulfilled, (state, action) => {
        state.loading = false;
        state.status = { success: true, message: action.payload };
      })
      .addCase(createLogoPost.rejected, (state, action) => {
        state.loading = false;
        state.status = {
          success: false,
          message: action.payload as string,
        };
      });
  },
});

export default bulkActionsSlice.reducer;
