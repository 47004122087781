import { Box, Modal, Skeleton, Typography } from "@mui/material";
import { startTransition, useEffect, useState } from "react";

import Chip from "components/Chip";
import { Button } from "components/Buttons";
import DateRangePicker from "components/DateRangePicker";
import { flexColumnCenterCenter, flexRowCenterCenter } from "utils/styles";
import logout from "assets/icons/logout.svg";
import down from "assets/icons/down.svg";
import print from "assets/icons/print.svg";
import Dashboard from "pages/Insights/Dashboard";
import Discovery from "pages/Insights/Discovery";
import Profile from "pages/Insights/Profile";
import Calls from "pages/Insights/Calls";
import Performance from "pages/Insights/Performance";
import Reviews from "pages/Insights/Reviews";
import Chat from "pages/Insights/Chat";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { getInsights } from "store/insightsSlice";

const tabs: { label: string; component: React.ReactNode }[] = [
  { label: "My Dashboard", component: <Dashboard /> },
  { label: "Discovery", component: <Discovery /> },
  { label: "Profile", component: <Profile /> },
  { label: "Calls", component: <Calls /> },
  { label: "Performance", component: <Performance /> },
  { label: "Reviews", component: <Reviews /> },
  { label: "Chat", component: <Chat /> },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 370,
  minWidth: 370,
  p: 3,
  pb: 1,
  borderRadius: "12px",
  minHeight: 300,
  maxHeight: 300,
  boxSizing: "border-box",
  ...flexColumnCenterCenter,
  alignItems: "flex-start",
  border: "none",
};

const Insights = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { insights } = useSelector((state: RootState) => state.insights);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getInsights());
  }, []);
  console.log({ insights });
  if (!user?.connected)
    return (
      <Box sx={{ maxWidth: "100%", p: 2, pl: 3 }}>
        <Box
          gap={2}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            pb: 4,
            flexWrap: "wrap",
          }}
        >
          <Skeleton variant="rectangular" width={250} height={35} />
          <Box
            gap={1}
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Skeleton variant="rectangular" width={100} height={37} />
            <Skeleton variant="rectangular" width={100} height={37} />
            <Skeleton variant="rectangular" width={100} height={37} />
          </Box>
        </Box>
        <Box
          gap={1}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            pb: 3,
          }}
        >
          {tabs.map((tab, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{ borderRadius: "100px" }}
              width={100}
              height={37}
            />
          ))}
        </Box>
        {selectedTab.component}
        <Modal open={true}>
          <Box
            sx={(theme) => ({
              backgroundColor: theme.palette.secondary.main,
              ...style,
            })}
          >
            <Typography variant="h2" sx={{ pb: 2.5 }}>
              Connect Account
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Please connect your Google account to access the Google Business
              Profiles
            </Typography>
            <Box sx={{ alignSelf: "center", my: 5 }}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "16px",
                  borderRadius: 1,
                  p: 1,
                  px: 3,
                  whiteSpace: "nowrap",
                  backgroundColor: "#1650CF",
                  border: "none",
                }}
                onClick={() => {
                  startTransition(() => {
                    navigate("/settings");
                  });
                }}
              >
                Go to settings
              </Button>
            </Box>
            <Typography
              variant="caption"
              color="textDisabled"
              sx={{ fontSize: 10, mt: "auto" }}
            >
              Account syncing may take 5-10 mins to reflect in the dashboard
            </Typography>
          </Box>
        </Modal>
      </Box>
    );

  return (
    <Box sx={{ maxWidth: "100%", p: 2, pl: 3 }}>
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          pb: 4,
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h3" sx={{ whiteSpace: "nowrap" }}>
          Insights - {selectedTab?.label}
        </Typography>
        <Box
          gap={1}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <Button
            startIcon={<img src={logout} alt="logout" />}
            endIcon={<img src={down} alt="down-arrow" />}
            sx={{
              py: 0,
              pl: 1,
              pr: "9.2px",
            }}
          >
            <Typography
              style={{
                padding: "8px 9.2px 8px 0",
                borderRight: "1px solid #d0d5dd",
              }}
            >
              Export
            </Typography>
          </Button>
          <Button
            startIcon={<img src={print} alt="print" />}
            sx={{
              ml: 1,
              p: 1,
            }}
          >
            <Typography>Summary</Typography>
          </Button>
          <DateRangePicker />
        </Box>
      </Box>
      <Box
        gap={1}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "flex-start",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        {tabs.map((tab, index) => (
          <Chip
            key={index}
            label={tab?.label}
            isSelected={selectedTab?.label === tab?.label}
            onClick={() => setSelectedTab(tab)}
          />
        ))}
      </Box>
      {selectedTab.component}
    </Box>
  );
};

export default Insights;
