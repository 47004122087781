export const getHealthStatus = (value: number) => {
  switch (true) {
    case value <= 80:
      return "Poor";
    case value >= 81 && value <= 85:
      return "Good";
    case value > 85:
      return "Excellent";
    default:
      return "";
  }
};

export const getRatingInt = (value: string) => {
  switch (value) {
    case "ONE":
      return 1;
    case "TWO":
      return 2;
    case "THREE":
      return 3;
    case "FOUR":
      return 4;
    case "FIVE":
      return 5;
    default:
      return null;
  }
};

export const getRatingString = (value: string) => {
  switch (value) {
    case "1":
      return "ONE";
    case "2":
      return "TWO";
    case "3":
      return "THREE";
    case "4":
      return "FOUR";
    case "5":
      return "FIVE";
    default:
      return null;
  }
};
