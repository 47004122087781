import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface InsightsState {
  insights: any[] | null;
  count: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: InsightsState = {
  insights: null,
  count: null,
  loading: false,
  error: null,
};

export const getInsights = createAsyncThunk(
  "/insights",
  async (_, thunkAPI) => {
    try {
      const response = await apiManager.get(`/insights`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get insights"
      );
    }
  }
);

const insightsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get insights
      .addCase(getInsights.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.insights = action.payload.data;
        state.count = action.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default insightsSlice.reducer;
