import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import apiManager from "utils/apiManager";

interface ReviewsState {
  reviews: { widget: any; data: any[] } | null;
  count: number | null;
  loading: boolean;
  success: string | null;
  reviewsAction: { success: boolean; message: string } | null;
  error: string | null;
  templates: any[] | null;
  templatesLoading: boolean;
}

const initialState: ReviewsState = {
  reviews: null,
  count: null,
  loading: false,
  success: null,
  reviewsAction: null,
  error: null,
  templates: null,
  templatesLoading: false,
};

const defaultPayload = {
  page: 1,
  limit: 10,
  response: "allResponses",
  sentiment: null,
  ratings: null,
  attributes: null,
  type: "",
  search: "",
  sort: [
    {
      column: "update_time",
      sort: "DESC",
    },
  ],
};

export const getReviews = createAsyncThunk(
  "/review-list",
  async (
    req: {
      page: number;
      limit: number;
      response: string;
      sentiment: string | null;
      ratings: any[] | null;
      // attributes: string | null;
      type: string;
      search: string;
      sort: any[];
      group_id: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/review-list", {
        page: req.page,
        limit: req.limit,
        response: req.response,
        sentiment: req.sentiment,
        ratings: req.ratings,
        // attributes: string | null;
        type: req.type,
        search: req.search,
        sort: req.sort,
        group_id: req.group_id,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get reviews list"
      );
    }
  }
);

export const deleteReply = createAsyncThunk(
  "/delete-review",
  async (req: { review_id: string }, thunkAPI) => {
    try {
      const response = await apiManager.delete(
        `/delete-review/${req.review_id}`
      );
      const { dispatch } = thunkAPI;
      const state = thunkAPI.getState() as RootState;
      dispatch(
        getReviews({
          ...defaultPayload,
          group_id: state?.groups?.masterGroup
            ? state?.groups?.masterGroup === "All"
              ? ""
              : state?.groups?.masterGroup
            : "",
        })
      );
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete review"
      );
    }
  }
);

export const editReply = createAsyncThunk(
  "/review-replay",
  async (req: { review_id: number; replay: string }, thunkAPI) => {
    try {
      const response = await apiManager.put(`/review-replay`, req);
      const state = thunkAPI.getState() as RootState;
      thunkAPI.dispatch(
        getReviews({
          ...defaultPayload,
          group_id: state?.groups?.masterGroup
            ? state?.groups?.masterGroup === "All"
              ? ""
              : state?.groups?.masterGroup
            : "",
        })
      );
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update"
      );
    }
  }
);

export const bulkReply = createAsyncThunk(
  "/bulk-review-replay",
  async (req: { id: number[]; comment: string }, thunkAPI) => {
    try {
      const response = await apiManager.post("/bulk-review-replay", req);
      const state = thunkAPI.getState() as RootState;
      thunkAPI.dispatch(
        getReviews({
          ...defaultPayload,
          group_id: state?.groups?.masterGroup
            ? state?.groups?.masterGroup === "All"
              ? ""
              : state?.groups?.masterGroup
            : "",
        })
      );
      return response?.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update"
      );
    }
  }
);

export const getTemplates = createAsyncThunk<
  any[],
  void,
  { rejectValue: string }
>("/templates", async (_, thunkAPI) => {
  try {
    const response = await apiManager.get("/template-list");
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(
      error.response?.data.message || "Failed to get templates"
    );
  }
});

export const createTemplate = createAsyncThunk(
  "/create-template",
  async (
    template: { template_title: string; template_body: string },
    thunkAPI
  ) => {
    try {
      const response = await apiManager.post("/create-template", template);
      const { dispatch } = thunkAPI;
      dispatch(getTemplates());
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data.message || "Failed to create template"
      );
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get reviews list
      .addCase(getReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviews.fulfilled, (state, action: any) => {
        state.reviews = action.payload;
        state.count = action.payload.totalCount;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.loading = false;
        state.reviews = null;
        state.error = action.payload as string;
      })
      //get templates
      .addCase(getTemplates.pending, (state) => {
        state.templatesLoading = true;
        state.error = null;
      })
      .addCase(getTemplates.fulfilled, (state, action: any) => {
        state.templates = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getTemplates.rejected, (state, action: any) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      // delete Reply
      .addCase(deleteReply.pending, (state) => {
        state.loading = true;
        state.reviewsAction = null;
      })
      .addCase(deleteReply.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewsAction = { success: true, message: action.payload };
      })
      .addCase(deleteReply.rejected, (state, action: any) => {
        state.reviewsAction = {
          success: false,
          message: action.payload as string,
        };
        state.loading = false;
      })
      // edit Reply
      .addCase(editReply.pending, (state) => {
        state.loading = true;
        state.reviewsAction = null;
      })
      .addCase(editReply.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewsAction = { success: true, message: action.payload };
      })
      .addCase(editReply.rejected, (state, action: any) => {
        state.reviewsAction = {
          success: false,
          message: action.payload as string,
        };
        state.loading = false;
      })

      // bulk Reply
      .addCase(bulkReply.pending, (state) => {
        state.loading = true;
        state.reviewsAction = null;
      })
      .addCase(bulkReply.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewsAction = { success: true, message: action.payload };
      })
      .addCase(bulkReply.rejected, (state, action: any) => {
        state.reviewsAction = {
          success: false,
          message: action.payload as string,
        };
        state.loading = false;
      });
  },
});

export default reviewsSlice.reducer;
