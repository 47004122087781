import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiManager from "utils/apiManager";

interface BulkActionsListingState {
  bulkActionListing: any;
  count: number | null;
  loading: boolean;
  error: string | null;
}

const initialState: BulkActionsListingState = {
  bulkActionListing: null,
  count: null,
  loading: false,
  error: null,
};

export const getBulkActionListing = createAsyncThunk(
  "/bulk-actionlocation-details",
  async (id: string, thunkAPI) => {
    try {
      const response = await apiManager.get(`location-details/${id}`);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to get listing"
      );
    }
  }
);

const bulkActionListingDetailsSlice = createSlice({
  name: "bulkActionsListing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get bulk actions listing
      .addCase(getBulkActionListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBulkActionListing.fulfilled, (state, action: any) => {
        state.bulkActionListing = action.payload.data;
        state.count = action.payload.totalCount;
        state.loading = false;
      })
      .addCase(getBulkActionListing.rejected, (state, action) => {
        state.bulkActionListing = null;
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default bulkActionListingDetailsSlice.reducer;
