import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Overview from "pages/BusinessProfiles/BusinessProfile/Overview";
import Post from "pages/BusinessProfiles/BusinessProfile/Post";
import Photos from "pages/BusinessProfiles/BusinessProfile/Photos";
import Services from "pages/BusinessProfiles/BusinessProfile/Services";
import ProfileBooster from "pages/BusinessProfiles/BusinessProfile/ProfileBooster";
import Attributes from "pages/BusinessProfiles/BusinessProfile/Attributes";
import Filters from "pages/BusinessProfiles/BusinessProfile/Filters";
import { flexRowCenterCenter } from "utils/styles";
import Chip from "components/Chip";
import back from "assets/icons/back.svg";
import edit from "assets/icons/pencil.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { getListing } from "store/listingDetailsSlice";

const tabs: {
  label: string;
  component: React.FC<{ isEdit?: boolean; onClose?: () => void }>;
}[] = [
  { label: "Overview", component: Overview },
  // { label: "Listings", component: Listings },
  { label: "Post", component: Post },
  { label: "Photos", component: Photos },
  { label: "Services", component: Services },
  { label: "Attributes", component: Attributes },
  { label: "Filters", component: Filters },
  { label: "Profile Booster", component: ProfileBooster },
];

const BusinessProfile = () => {
  const [selectedTab, setSelectedTab] = useState({
    tab: tabs[0],
    isEdit: false,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) dispatch(getListing(id));
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "100%",
        minHeight: "calc(100vh - 65px)",
        p: 2,
        pl: 3,
        textAlign: "left",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start", pb: 4 }}>
        <IconButton onClick={() => navigate("/business-profiles")}>
          {" "}
          <img src={back} alt="back-button" />
        </IconButton>
        <Typography variant="h3">Profile</Typography>
      </Box>
      <Box
        gap={1}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "flex-start",
          flexWrap: "wrap",
          pb: 3,
        }}
      >
        {tabs.map((tab, index) => (
          <Chip
            key={index}
            label={tab?.label}
            sx={{
              width: 110,
              display: "flex",
              justifyContent: "space-between",
            }}
            isSelected={selectedTab.tab?.label === tab?.label}
            onClick={() => setSelectedTab({ tab, isEdit: false })}
            deleteIcon={
              tab.label === "Profile Booster" ? (
                <div></div>
              ) : (
                <IconButton
                  onClick={() => setSelectedTab({ tab, isEdit: true })}
                >
                  <img src={edit} alt="edit" />
                </IconButton>
              )
            }
            onDelete={() =>
              tab.label === "Profile Booster"
                ? null
                : setSelectedTab({ tab, isEdit: true })
            }
          />
        ))}
      </Box>
      <selectedTab.tab.component
        isEdit={selectedTab.isEdit}
        onClose={() => setSelectedTab({ ...selectedTab, isEdit: false })}
      />
    </Box>
  );
};

export default BusinessProfile;
