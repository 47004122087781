import Swal, { SweetAlertOptions } from "sweetalert2";

export const sweetAlert = (props: SweetAlertOptions) => {
  return Swal.fire({
    position: "bottom-right",
    timer: 2000,
    timerProgressBar: true,
    toast: true,
    ...props,
  });
};
