import {
  Box,
  Chip,
  Divider,
  Grid2,
  IconButton,
  MenuItem,
  Modal,
  Pagination,
  Rating,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CustomDateRangePicker from "components/DateRangePicker";
import React, { useEffect, useState } from "react";
import { flexRowCenterCenter } from "utils/styles";
import ArrowLongUp from "assets/icons/arrowLongUp";
import ArrowLongDown from "assets/icons/arrowLongDown";
import star from "assets/icons/filled-star.svg";
import google from "assets/icons/google.svg";
import share from "assets/icons/share.svg";
import pencil from "assets/icons/pencil.svg";
import filter from "assets/icons/filters-lines.svg";
import trash from "assets/icons/trash.svg";
import settings from "assets/icons/settings.svg";
import { BorderLinearProgress } from "pages/Insights/Components";
import Checkbox from "components/Checkbox";
import ProfileImageContainer from "components/ProfileImageContainer";
import Dot from "components/Dot";
import { Button } from "components/Buttons";
import Select, { DropDown } from "components/Select";
import Searchbar from "components/Searchbar";
import { BulkReplyModal, FilterModal, IndividualReplyModal } from "./Modals";
import { Review, ReviewFilters } from "interfaces/Reviews.types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { deleteReply, getReviews } from "store/reviewsSlice";
import { formatDistanceToNow, parseISO } from "date-fns";
import useDebounce from "customHooks/useDebounce";
import { ReviewsBottomSkeleton } from "components/Skeletons/ReviewSkeleton";
import { theme } from "utils/theme";
import { sweetAlert } from "utils/sweetAlert";
import { getRatingString } from "utils/functions";

const ratingsData = [
  { rating: 5, color: "#5EC37F" },
  { rating: 4, color: "#AD95FB" },
  { rating: 3, color: "#FFC564" },
  { rating: 2, color: "#608DF1" },
  { rating: 1, color: "#E45858" },
];

const tabs = [
  { id: "", label: "All" },
  { id: "replied", label: "Replied" },
  { id: "Not Replied", label: "Unreplied" },
];

const sortValues = {
  newest: {
    column: "update_time",
    sort: "DESC",
  },
  highestRating: {
    column: "star_rating",
    sort: "DESC",
  },
  lowestRating: {
    column: "star_rating",
    sort: "ASC",
  },
};

const Reviews = () => {
  const { reviews, count, error, loading, reviewsAction } = useSelector(
    (state: RootState) => state.reviews
  );
  const { masterGroup } = useSelector((state: RootState) => state.groups);
  const dispatch = useDispatch<AppDispatch>();
  const [reviewsData, setReviewsData] = useState<any[] | null | undefined>(
    null
  );
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);
  const [openBulkReplyModal, setOpenBulkReplyModal] = useState(false);
  const [individualReplyModal, setIndividualReplyModal] = useState<{
    open: boolean;
    review: Review | null;
  }>({ open: false, review: null });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState<ReviewFilters>({
    response: "",
    sentiment: null,
    ratings: null,
    // attributes: null,
    type: "",
    search: "",
  });
  const [sort, setSort] = useState<"newest" | "highestRating" | "lowestRating">(
    "newest"
  );
  const [pagination, setPagination] = useState<{
    page: number;
    rowsPerPage: number;
  }>({
    page: 1,
    rowsPerPage: 10,
  });
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    setActiveTab(
      filters.response
        ? tabs?.find((tab) => tab.id === filters.response) || activeTab
        : activeTab
    );
  }, [filters.response]);

  useEffect(() => {
    dispatch(
      getReviews({
        page: pagination.page,
        limit: pagination.rowsPerPage,
        ...filters,
        sentiment: filters.sentiment?.join(",") || null,
        ratings: filters.ratings
          ? filters.ratings?.map((rating) => getRatingString(rating))
          : null,
        // attributes: filters.attributes?.join(",") || null,
        search: searchDebounce,
        sort: [sortValues[sort]],
        group_id: masterGroup ? (masterGroup === "All" ? "" : masterGroup) : "",
      })
    );
  }, [pagination, searchDebounce, sort, masterGroup]);

  useEffect(() => {
    if (reviewsAction) {
      if (reviewsAction?.success)
        sweetAlert({
          title: "Success!",
          icon: "success",
          text: reviewsAction?.message,
        });
      else if (!reviewsAction?.success)
        sweetAlert({
          title: "Error!",
          icon: "error",
          text: reviewsAction?.message || `Something went wrong!`,
        });
    }
  }, [reviewsAction]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  useEffect(() => {
    setReviewsData(reviews?.data?.map((data) => ({ ...data, checked: false })));
  }, [reviews]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    setReviewsData((prevData) =>
      prevData?.map((data) => ({ ...data, checked: event.target.checked }))
    );
  };

  const onSelectReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    selected: Review
  ) => {
    setReviewsData(
      reviewsData
        ? reviewsData.map((data) => {
            if (data.review_id === selected.review_id) {
              return { ...data, checked: event.target.checked };
            }
            return data;
          })
        : null
    );
  };

  const handleDelete = (currentReview: any) =>
    dispatch(deleteReply({ review_id: currentReview?.id }));

  const onChangeTab = (tab: any) => {
    setActiveTab(tab);
    setFilters({ ...filters, response: tab.id });
    dispatch(
      getReviews({
        page: pagination.page,
        limit: pagination.rowsPerPage,
        ...filters,
        sentiment: filters.sentiment?.join(",") || null,
        ratings: filters.ratings
          ? filters.ratings?.map((rating) => getRatingString(rating))
          : null,
        // attributes: filters.attributes?.join(",") || null,
        response: tab.id,
        sort: [sortValues[sort]],
        group_id: masterGroup ? (masterGroup === "All" ? "" : masterGroup) : "",
      })
    );
    switch (tab.id) {
      case 1:
        setReviewsData(reviews?.data);
        return;
      case 2:
        setReviewsData(
          reviews ? reviews?.data.filter((data) => data.reply_comment) : []
        );
        return;
      case 3:
        setReviewsData(
          reviews ? reviews?.data.filter((data) => !data.reply_comment) : []
        );
        return;
      default:
        return;
    }
  };

  const handleBulkReply = () => setOpenBulkReplyModal(true);

  const onSelectSort = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSort(e.target.value as "newest" | "highestRating" | "lowestRating");
  };

  return (
    <Box
      sx={{ width: "100%", p: 3, boxSizing: "border-box", textAlign: "left" }}
    >
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          pb: 4,
        }}
      >
        <Typography variant="h5">Reviews</Typography>
        <CustomDateRangePicker />
      </Box>
      {error ? (
        error
      ) : (
        <>
          <Grid2 container gap={2}>
            <Grid2
              size={{ xs: 12, lg: 3.8 }}
              sx={(theme) => ({
                borderRight: isSmallDevice
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
                borderBottom: !isSmallDevice
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
                ...flexRowCenterCenter,
                justifyContent: isSmallDevice ? "flex-start" : "center",
                pb: 2,
              })}
            >
              <Box>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  Total Reviews
                </Typography>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    pb: 0.5,
                  }}
                >
                  <Typography variant="h1">
                    {reviews?.widget?.totalReviews?.value}
                  </Typography>
                  <Chip
                    sx={{
                      backgroundColor:
                        reviews?.widget?.totalReviews?.growthPercentage >= 0
                          ? "#E8F6ED"
                          : "#fae2dd",
                      color:
                        reviews?.widget?.totalReviews?.growthPercentage >= 0
                          ? "#137A5F"
                          : "maroon",
                      pl: 1,
                      "& .MuiChip-label": { pl: 0.5 },
                    }}
                    label={
                      reviews?.widget?.totalReviews?.growthPercentage >= 0
                        ? reviews?.widget?.totalReviews?.growthPercentage
                        : reviews?.widget?.totalReviews?.growthPercentage?.slice(
                            1
                          )
                    }
                    icon={
                      reviews?.widget?.totalReviews?.growthPercentage >= 0 ? (
                        <ArrowLongUp color="#137A5F" />
                      ) : (
                        <ArrowLongDown color="maroon" />
                      )
                    }
                  />
                </Box>
                <Typography variant="body2" sx={{ pb: 0.5 }}>
                  Growth in reviews on this year
                </Typography>
              </Box>
            </Grid2>
            <Grid2
              size={{ xs: 12, lg: 3.8 }}
              sx={(theme) => ({
                borderRight: isSmallDevice
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
                borderBottom: !isSmallDevice
                  ? "none"
                  : `1px solid ${theme.palette.grey["200"]}`,
                ...flexRowCenterCenter,
                justifyContent: isSmallDevice ? "flex-start" : "center",
                pb: 2,
              })}
            >
              <Box>
                <Typography variant="body1" sx={{ pb: 3 }}>
                  Average Rating
                </Typography>
                <Box
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    pb: 0.5,
                  }}
                >
                  <Typography variant="h1" sx={{ pr: 1 }}>
                    {reviews?.widget?.averageRating?.value}
                  </Typography>
                  <Rating
                    readOnly
                    value={parseInt(reviews?.widget?.averageRating?.stars)}
                    precision={0.5}
                  />
                </Box>
                <Typography variant="body2" sx={{ pb: 0.5 }}>
                  Growth in reviews on this year
                </Typography>
              </Box>
            </Grid2>
            <Grid2
              size={{ xs: 12, lg: 3.8 }}
              sx={() => ({
                ...flexRowCenterCenter,
                justifyContent: isSmallDevice ? "flex-start" : "center",
                mb: 2,
              })}
            >
              <Box>
                {reviews?.widget &&
                  ratingsData.map((rating, index) => (
                    <Box
                      sx={{
                        ...flexRowCenterCenter,
                        justifyContent: "flex-start",
                        pb: 0.5,
                      }}
                      key={index}
                    >
                      <img src={star} alt="star" />
                      <Typography variant="body1" sx={{ pl: 1, pr: 2 }}>
                        {rating?.rating}
                      </Typography>
                      <BorderLinearProgress
                        value={
                          reviews?.widget?.ratingsBreakdown[rating?.rating]
                        }
                        bgcolor={rating.color}
                        variant="determinate"
                        sx={{ minWidth: 180, mr: 2 }}
                      />
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        {reviews?.widget?.ratingsBreakdown[rating?.rating]}%
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Grid2>
          </Grid2>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Box
              gap={2}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <Checkbox
                  sx={(theme) => ({
                    color: theme.palette.primary.main,
                  })}
                  value={selectAll}
                  onChange={handleSelectAll}
                />
                {tabs.map((tab) => (
                  <Typography
                    key={tab.id}
                    variant="body1"
                    sx={{
                      borderBottom:
                        tab.id === activeTab.id ? "4px solid #440BFF" : "",
                      mx: 2,
                      p: 1,
                      cursor: "pointer",
                      boxSizing: "border-box",
                    }}
                    onClick={() => onChangeTab(tab)}
                  >
                    {tab.label}
                  </Typography>
                ))}
              </Box>
              <Box
                gap={2}
                sx={{
                  ...flexRowCenterCenter,
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                <Select value={sort} onChange={onSelectSort}>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px", display: "none" }}
                    value="mostRelevant"
                  >
                    Most relevant
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value="newest"
                  >
                    Newest
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value="highestRating"
                  >
                    Highest rating
                  </MenuItem>
                  <MenuItem
                    sx={{ fontWeight: 400, fontSize: "14px" }}
                    value="lowestRating"
                  >
                    Lowest rating
                  </MenuItem>
                </Select>
                <Button
                  sx={{ borderRadius: "4px" }}
                  onClick={() => setOpenFilterModal(true)}
                  endIcon={<img src={filter} alt="filter" />}
                  size="medium"
                >
                  Filter
                </Button>
                <Button
                  sx={{
                    color: "#1650CF",
                    borderColor: "#1650CF",
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                  }}
                  size="medium"
                  onClick={handleBulkReply}
                >
                  Bulk Reply
                </Button>
                <Searchbar
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{ ml: 0, mt: 0 }}
                />
                <IconButton>
                  <img src={settings} alt="settings" sizes="37px" />
                </IconButton>
              </Box>
            </Box>
            {loading ? (
              <ReviewsBottomSkeleton />
            ) : (
              <>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 600, pt: 3 }}>
                    {count} Reviews
                  </Typography>
                  {reviewsData?.map((data: any) => (
                    <Box
                      key={data.review_id}
                      sx={(theme) => ({
                        display: "flex",
                        alignItems: "flex-start",
                        py: 4,
                        borderBottom: `1px solid ${theme.palette.grey["200"]}`,
                      })}
                    >
                      <Checkbox
                        checked={data.checked}
                        onChange={(event) => onSelectReview(event, data)}
                      />
                      <Box sx={{ mr: 3 }}>
                        <ProfileImageContainer
                          pic={data.reviewer_photo}
                          icon={google}
                        />
                      </Box>
                      <Box sx={{ maxWidth: "80%" }}>
                        <Typography variant="body1">
                          {data.reviewer_name}
                        </Typography>
                        <Tooltip title={data.title}>
                          <Typography
                            variant="caption"
                            sx={{ display: "inline" }}
                          >
                            {data.title}
                          </Typography>
                        </Tooltip>
                        <br />
                        <Tooltip title={data.locality}>
                          <Typography
                            variant="caption"
                            color="textDisabled"
                            sx={{ pb: 0.5 }}
                          >
                            {data.locality}
                          </Typography>
                        </Tooltip>
                        <Box
                          sx={{
                            ...flexRowCenterCenter,
                            justifyContent: "flex-start",
                            pt: 0.5,
                            pb: 2,
                          }}
                        >
                          <Rating
                            readOnly
                            value={parseFloat(data.star_rating)}
                            precision={0.5}
                          />
                          <Dot />
                          <Typography variant="caption" color="textDisabled">
                            {data?.create_time
                              ? formatDistanceToNow(
                                  parseISO(data?.create_time),
                                  {
                                    addSuffix: true,
                                  }
                                )
                              : ""}
                          </Typography>
                        </Box>
                        <Typography variant="body2" sx={{ pb: 2 }}>
                          {data.comment}
                        </Typography>

                        {data.reply_comment ? (
                          <Box>
                            <Box
                              sx={(theme) => ({
                                borderLeft: `4px solid ${theme.palette.grey["200"]}`,
                                pl: 1,
                                mb: 2,
                              })}
                            >
                              <Box
                                sx={{
                                  ...flexRowCenterCenter,
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Typography variant="body2">
                                  Response from the owner
                                </Typography>
                                <Dot />
                                <Typography
                                  variant="caption"
                                  color="textDisabled"
                                >
                                  {data?.reply_time
                                    ? formatDistanceToNow(
                                        parseISO(data?.reply_time),
                                        {
                                          addSuffix: true,
                                        }
                                      )
                                    : ""}
                                </Typography>
                              </Box>
                              <Typography variant="body2" color="textDisabled">
                                {data.reply_comment}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                ...flexRowCenterCenter,
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                variant="text"
                                sx={{ border: "none", fontSize: "12px", mr: 1 }}
                                startIcon={<img src={pencil} alt="edit" />}
                                onClick={() =>
                                  setIndividualReplyModal({
                                    review: data,
                                    open: true,
                                  })
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                variant="text"
                                sx={{ border: "none", fontSize: "12px" }}
                                startIcon={<img src={trash} alt="delete" />}
                                onClick={() => handleDelete(data)}
                              >
                                Delete
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Button
                            variant="text"
                            sx={{ border: "none" }}
                            startIcon={<img src={share} alt="reply" />}
                            onClick={() =>
                              setIndividualReplyModal({
                                review: data,
                                open: true,
                              })
                            }
                          >
                            Reply
                          </Button>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Box
                  gap={2}
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    pt: 4,
                  }}
                >
                  <Box
                    gap={2}
                    sx={{
                      ...flexRowCenterCenter,
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <DropDown
                      label="Rows per rage"
                      width={100}
                      onChange={(e: any) =>
                        setPagination({
                          ...pagination,
                          rowsPerPage: e.target.value,
                        })
                      }
                      value={pagination.rowsPerPage}
                    >
                      {[10, 50, 100].map((rows, index) => (
                        <MenuItem
                          value={rows}
                          key={index}
                          sx={{ fontWeight: 500, fontSize: "14px" }}
                        >
                          {rows}
                        </MenuItem>
                      ))}
                    </DropDown>
                    <Typography variant="body2">
                      {count &&
                        `Showing
                      ${
                        count < pagination?.rowsPerPage
                          ? count
                          : pagination?.rowsPerPage
                      }
                      of ${count}`}
                    </Typography>
                  </Box>

                  <Pagination
                    count={
                      count ? Math.round(count / pagination?.rowsPerPage) : 0
                    }
                    variant="outlined"
                    shape="rounded"
                    page={pagination?.page}
                    onChange={handleChangePage}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#222124",
                      },
                      "& .MuiPaginationItem-root.Mui-selected": {
                        color: "#1976d2",
                        borderColor: "#1976d2",
                        backgroundColor: "#ffffff",
                        fontWeight: 500,
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
      <Modal
        open={openBulkReplyModal}
        onClose={() => setOpenBulkReplyModal(false)}
      >
        <BulkReplyModal
          selectedReviews={reviewsData?.filter((data) => data.checked)}
          onClose={() => setOpenBulkReplyModal(false)}
        />
      </Modal>
      <Modal
        open={individualReplyModal.open}
        onClose={() =>
          setIndividualReplyModal({ ...individualReplyModal, open: false })
        }
      >
        <IndividualReplyModal
          selectedReview={individualReplyModal.review}
          onClose={() =>
            setIndividualReplyModal({ ...individualReplyModal, open: false })
          }
        />
      </Modal>
      <Modal open={openFilterModal} onClose={() => setOpenFilterModal(false)}>
        <FilterModal
          filters={filters}
          setFilters={(modifiedFilters) => setFilters(modifiedFilters)}
          onClose={() => setOpenFilterModal(false)}
        />
      </Modal>
    </Box>
  );
};

export default Reviews;
