import { Avatar, Box, Typography, TextField, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { flexRowCenterCenter } from "utils/styles";

import google from "assets/icons/google.svg";
import arrowUpRight from "assets/icons/arrow-up-right.svg";
import { Button } from "components/Buttons";
import close from "assets/icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  createGroup,
  deleteGroup,
  getGroups,
  renameGroup,
} from "store/groupSlice";
import { sweetAlert } from "utils/sweetAlert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: 750,
  minWidth: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "12px",
  maxHeight: "90%",
};

type Group = { id: number; group: string; isEdit: boolean };

const Settings = () => {
  const [group, setGroup] = useState({ add: false, val: "" });
  const { groups: initialGroups, groupsAction } = useSelector(
    (state: RootState) => state.groups
  );
  const dispatch = useDispatch<AppDispatch>();
  const [groups, setGroups] = useState<any[] | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const [connected, setConnected] = useState(user?.connected);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    dispatch(getGroups());
  }, []);

  useEffect(() => {
    setGroups(
      initialGroups?.map((group) => ({ ...group, isEdit: false })) || []
    );
  }, [initialGroups]);

  useEffect(() => {
    console.log({ groupsAction });
    if (groupsAction && !groupsAction.success)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: groupsAction.message,
      });
    else if (groupsAction && groupsAction.success)
      sweetAlert({
        title: "Success!",
        icon: "success",
        text: groupsAction.message,
      });
  }, [groupsAction]);

  const editGroups = (
    currentGroup: Group,
    action: string,
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newGroups = groups?.map((group) => {
      if (group.id === currentGroup.id) {
        if (action === "onChange")
          return { ...group, group_name: e?.target.value?.slice(0, 15) };
        if (action === "edit") return { ...group, isEdit: true };
        else if (action === "cancel") {
          const oldGroup = initialGroups?.find(
            (group) => group.id === currentGroup.id
          );
          return { ...oldGroup, isEdit: false };
        }
      }
      return group;
    });
    setGroups(newGroups ? [...newGroups] : null);
  };

  const saveNewGroup = () => {
    setGroup({ add: false, val: "" });
    dispatch(createGroup({ label: group.val }));
  };
  return (
    <Box sx={{ maxWidth: "100%", p: 2, pl: 3, textAlign: "left" }}>
      <Typography variant="h3" sx={{ mb: 3 }}>
        {" "}
        Settings{" "}
      </Typography>
      <Typography variant="caption" color="textDisabled">
        Improve and increase your customer engagement metrics and grow bigger in
        your local market by connecting various platforms.
      </Typography>
      <Typography variant="h5" sx={{ pt: 2 }}>
        Connected Platforms
      </Typography>
      <Box
        sx={(theme) => ({
          borderRadius: "8px",
          border: `1px solid ${theme.palette.grey["300"]}`,
          p: "32px 24px",
          width: 330,
          mt: 3,
        })}
      >
        <Box sx={{ ...flexRowCenterCenter, justifyContent: "space-between" }}>
          <Avatar
            sx={(theme) => ({
              backgroundColor: "transparent",
              width: 42,
              height: 42,
              border: `1px solid ${theme.palette.grey["200"]}`,
            })}
          >
            <img src={google} alt="google" sizes="32px" />
          </Avatar>
          <Typography
            variant="caption"
            sx={{ color: connected ? "rgba(15, 183, 29, 1)" : "#E73F3F" }}
          >
            {connected ? "Connected" : "Not Connected"}
          </Typography>
        </Box>
        <Typography variant="body1">Google</Typography>
        <Typography variant="caption" color="textDisabled">
          {connected
            ? "Click on manage listings to view the business profiles"
            : "Connect account to view the business profiles"}
        </Typography>
        <br />
        {connected ? (
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, cursor: "pointer" }}
              onClick={() => setConnected(false)}
            >
              Logout
            </Typography>
            <Link
              to="/business-profiles"
              style={{
                color: "#1650CF",
                fontSize: "14px",
                textDecoration: "none",
                marginRight: "12px",
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
              }}
            >
              Manage Listings
              <img
                src={arrowUpRight}
                alt="arrow-up-right"
                sizes="16px"
                style={{
                  marginLeft: "12px",
                }}
              />
            </Link>
          </Box>
        ) : (
          <Typography
            variant="body2"
            component="a"
            color="primary"
            sx={{
              fontWeight: 500,
              pt: 2,
              cursor: "pointer",
              textDecoration: "none",
            }}
            href={process.env.REACT_APP_GOOGLE_REDIRECT_URI}
          >
            Connect
          </Typography>
        )}
      </Box>
      <Typography variant="h3" sx={{ mb: 1, mt: 5 }}>
        {" "}
        Business Profile Group{" "}
      </Typography>
      <Typography variant="caption" color="textDisabled">
        Create group name and tag them with the desired business profile to
        create master filter
      </Typography>
      <Box sx={{ pt: 2 }}>
        <Button
          sx={{ borderColor: "#1650CF", color: "#1650CF" }}
          onClick={() => setGroup({ ...group, add: true })}
        >
          Add new
        </Button>
        <Box
          sx={(theme) => ({
            p: 2,
            my: 3,
            border: `1px solid ${theme.palette.grey["200"]}`,
            maxHeight: 300,
            maxWidth: 650,
            borderRadius: "8px",
            overflowY: "scroll",
          })}
        >
          {group.add ? (
            <Box
              gap={1}
              sx={{
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <Box>
                <TextField
                  variant="filled"
                  size="small"
                  sx={{
                    mt: 2,
                    mb: 0,
                    width: 265,
                    "& .MuiFilledInput-root": { fontWeight: 400 },
                    "& .MuiFilledInput-root::before": { borderBottom: "none" },
                  }}
                  value={group?.val}
                  onChange={(e) =>
                    setGroup({ ...group, val: e.target.value.slice(0, 15) })
                  }
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="caption">
                    {group?.val?.length}/15
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{ color: "#1650CF", border: "none" }}
                onClick={saveNewGroup}
              >
                Save
              </Button>
              <Button
                sx={{ color: "#1650CF", border: "none" }}
                onClick={() => setGroup({ add: false, val: "" })}
              >
                Cancel
              </Button>
            </Box>
          ) : null}
          {groups?.map((group) => (
            <Box key={group.id}>
              {!group.isEdit ? (
                <Box
                  gap={1}
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={(theme) => ({
                      width: 265,
                      boxSizing: "border-box",
                      fontWeight: 400,
                      border: `1px solid ${theme.palette.grey["200"]}`,
                      p: 1,
                      borderRadius: "8px",
                    })}
                  >
                    {group?.group_name}
                  </Typography>
                  <Button
                    sx={{ color: "#1650CF", border: "none" }}
                    onClick={() => editGroups(group, "edit")}
                  >
                    Edit
                  </Button>
                </Box>
              ) : (
                <Box
                  gap={1}
                  sx={{
                    ...flexRowCenterCenter,
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  <Box width={265}>
                    <TextField
                      variant="filled"
                      size="small"
                      sx={{
                        mt: 2,
                        mb: 0,
                        width: 265,
                        "& .MuiFilledInput-root": { fontWeight: 400 },
                        "& .MuiFilledInput-root::before": {
                          borderBottom: "none",
                        },
                      }}
                      fullWidth
                      value={group?.group_name}
                      onChange={(e) => editGroups(group, "onChange", e)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Typography variant="caption" pb={1}>
                        {group?.group_name?.length}/15
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    sx={{ color: "#1650CF", border: "none" }}
                    onClick={() =>
                      dispatch(
                        renameGroup({
                          group_id: group?.id,
                          group_name: group?.group_name,
                        })
                      )
                    }
                  >
                    Save
                  </Button>
                  <Button
                    sx={{ color: "#1650CF", border: "none" }}
                    onClick={() => setOpenDelete(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    sx={{ color: "#1650CF", border: "none" }}
                    onClick={() => editGroups(group, "cancel")}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
              <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
                <Box
                  sx={(theme) => ({
                    backgroundColor: theme.palette.secondary.main,
                    ...style,
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["100"],
                      p: "20px 24px",
                      ...flexRowCenterCenter,
                      justifyContent: "space-between",
                      borderTopLeftRadius: "12px",
                      borderTopRightRadius: "12px",
                    })}
                  >
                    <Typography variant="h5">Deleting Group</Typography>
                    <img
                      src={close}
                      alt="close"
                      sizes="24px"
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenDelete(false)}
                    />
                  </Box>
                  <Box p={3}>
                    <Typography variant="body1" fontWeight={400}>
                      Group name once deleted cannot be reversed, you may still
                      find all the respective listings under default all listing
                      group name
                    </Typography>
                    <Box
                      py={2}
                      gap={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button onClick={() => setOpenDelete(false)}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#1650CF", border: "none" }}
                        onClick={() => {
                          if (location)
                            dispatch(
                              deleteGroup({
                                group_id: group?.id,
                              })
                            );
                          setOpenDelete(false);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
