import React from "react";

const arrowLongDown: React.FC<{ color?: string }> = ({ color = "#32AE08" }) => {
  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 19C5.79289 19 5.625 18.8421 5.625 18.625V5.375C5.625 5.15789 5.79289 5 6 5C6.20711 5 6.375 5.15789 6.375 5.375V18.625C6.375 18.8421 6.20711 19 6 19ZM6 19L2.5 13L3.5 12L6 15.5L8.5 12L9.5 13L6 19Z"
      />
    </svg>
  );
};

export default arrowLongDown;
