import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  Divider,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { forwardRef, useState } from "react";
import close from "assets/icons/close-white.svg";
import plus from "assets/icons/add-white.svg";
import search from "assets/icons/search.svg";
import {
  bulkActionsContentStyle,
  bulkActionsInnerContentStyle,
  flexRowCenterCenter,
} from "utils/styles";
import TextField, { CustomTextField } from "components/TextField";
import { Button } from "components/Buttons";

interface OptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<OptionType>();

const Labels: React.FC<{ setActionOrOpen: any }> = forwardRef(
  ({ setActionOrOpen }, ref) => {
    const [selectedGroup, setSelectedGroup] = useState<string | null>(
      "Bata India"
    );
    const [selectedLabels, setSelectedLabels] = useState<(string | null)[]>([]);
    const options: readonly OptionType[] = [
      { title: "Bata India" },
      { title: "Nike India" },
    ];
    return (
      <Box ref={ref} sx={bulkActionsContentStyle}>
        <Box sx={bulkActionsInnerContentStyle}>
          <Typography
            variant="h6"
            sx={(theme) => ({
              fontWeight: 600,
              p: 2,
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            })}
          >
            Groups and Labels
          </Typography>
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.palette.grey["200"]}`,
              p: 2,
            })}
          >
            <Typography variant="body1" sx={() => ({ fontWeight: 600, pb: 1 })}>
              Profile Group
            </Typography>
            <Typography variant="caption" color="textDisabled">
              Only one profile group can be used for adding into selected
              business profile. Chosen group name will overwrite the already
              existing group name in selected profiles
            </Typography>
            <Box pt={2} pb={1}>
              {selectedGroup ? (
                <Chip
                  variant="filled"
                  sx={(theme) => ({
                    backgroundColor: "#1650CF",
                    color: theme.palette.secondary.main,
                  })}
                  label={selectedGroup}
                  deleteIcon={
                    <img
                      src={close}
                      alt="close"
                      style={{ width: 14, height: 14 }}
                    />
                  }
                  onDelete={() => setSelectedGroup(null)}
                />
              ) : null}
            </Box>
            <TextField
              color="primary"
              size="small"
              variant="outlined"
              placeholder="Search"
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src={search} alt="search" width={16} height={16} />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{
                mb: 0,
                minWidth: "60%",
              }}
              type="search"
            />
            <Box
              gap={1}
              sx={() => ({
                ...flexRowCenterCenter,
                justifyContent: "flex-start",
                flexWrap: "wrap",
                maxWidth: "60%",
                py: 2,
              })}
            >
              {["Bata India", "Nike India", "Nalli Chennai"].map(
                (val, index) => (
                  <Chip
                    key={index}
                    label={val}
                    icon={<img src={plus} alt="plus" />}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.grey["500"],
                      color: theme.palette.secondary.main,
                      fontWeight: 600,
                    })}
                    onClick={() => setSelectedGroup(val)}
                  />
                )
              )}
            </Box>
          </Box>
          <Box sx={() => ({ p: 2 })}>
            <Typography variant="body1" sx={() => ({ fontWeight: 600, pb: 1 })}>
              Filter Labels
            </Typography>
            <Typography variant="caption" color="textDisabled">
              You can create or choose multiple labels to create various levels
              of filter options
            </Typography>
            <Box pt={2} gap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
              {selectedLabels.map((label, index) => (
                <Chip
                  key={index}
                  variant="filled"
                  sx={(theme) => ({
                    backgroundColor: "#1650CF",
                    color: theme.palette.secondary.main,
                  })}
                  label={label}
                  deleteIcon={
                    <img
                      src={close}
                      alt="close"
                      style={{ width: 14, height: 14 }}
                    />
                  }
                  onDelete={() =>
                    setSelectedLabels(
                      selectedLabels.filter(
                        (currentLabel) => currentLabel !== label
                      )
                    )
                  }
                />
              ))}
            </Box>
            <Autocomplete
              value=""
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  if (!selectedLabels.includes(newValue))
                    setSelectedLabels([...selectedLabels, newValue]);
                } else if (newValue && newValue?.inputValue) {
                  if (!selectedLabels.includes(newValue.inputValue))
                    setSelectedLabels([...selectedLabels, newValue.inputValue]);
                } else {
                  if (newValue) {
                    if (!selectedLabels.includes(newValue?.title))
                      setSelectedLabels([...selectedLabels, newValue.title]);
                  }
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `"${inputValue}" (New Label)`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="select-labels"
              options={options}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.title;
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li
                    key={key}
                    style={{ fontWeight: 400, fontSize: "14px" }}
                    {...optionProps}
                  >
                    {option.title}
                  </li>
                );
              }}
              sx={{
                width: 300,
                fontWeight: 400,
                fontSize: "14px",
                minWidth: "60%",
              }}
              freeSolo
              size="small"
              renderInput={(props) => (
                <CustomTextField
                  sx={{ p: 0 }}
                  {...props}
                  variant="outlined"
                  placeholder="Search Labels"
                  slotProps={{
                    input: {
                      ...props.InputProps,
                      endAdornment: (
                        <>
                          {props.InputProps.endAdornment}
                          <img
                            src={search}
                            alt="search"
                            style={{ width: 20, height: 20 }}
                          />
                        </>
                      ),
                    },
                  }}
                />
              )}
            />
          </Box>
        </Box>
        <Box>
          <Divider sx={{ mt: "auto" }} />
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "flex-end",
              p: "10px 16px",
              justifySelf: "flex-end",
            }}
          >
            <Button
              sx={{ mr: 1 }}
              onClick={() => setActionOrOpen({ open: false })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#1650CF", border: "none" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Labels;
