import { Box, Grid2, Skeleton, useMediaQuery } from "@mui/material";
import { flexRowCenterCenter } from "utils/styles";
import { theme } from "utils/theme";

export const ReviewsTopSkeleton = () => {
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid2 container gap={2}>
      <Grid2
        size={{ xs: 12, lg: 3.8 }}
        sx={(theme) => ({
          borderRight: isSmallDevice
            ? "none"
            : `1px solid ${theme.palette.grey["200"]}`,
          borderBottom: !isSmallDevice
            ? "none"
            : `1px solid ${theme.palette.grey["200"]}`,
          ...flexRowCenterCenter,
          justifyContent: isSmallDevice ? "flex-start" : "center",
          pb: 2,
        })}
      >
        <Box>
          <Skeleton
            variant="rectangular"
            height={20}
            width={200}
            sx={{ mb: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={20}
            width={100}
            sx={{ mb: 3 }}
          />
          <Skeleton variant="rectangular" height={20} width={250} />
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 3.8 }}
        sx={(theme) => ({
          borderRight: isSmallDevice
            ? "none"
            : `1px solid ${theme.palette.grey["200"]}`,
          borderBottom: !isSmallDevice
            ? "none"
            : `1px solid ${theme.palette.grey["200"]}`,
          ...flexRowCenterCenter,
          justifyContent: isSmallDevice ? "flex-start" : "center",
          pb: 2,
        })}
      >
        <Box>
          <Skeleton
            variant="rectangular"
            height={20}
            width={200}
            sx={{ mb: 1 }}
          />
          <Skeleton
            variant="rectangular"
            height={20}
            width={100}
            sx={{ mb: 3 }}
          />
          <Skeleton variant="rectangular" height={20} width={250} />
        </Box>
      </Grid2>
      <Grid2
        size={{ xs: 12, lg: 3.8 }}
        sx={() => ({
          ...flexRowCenterCenter,
          justifyContent: isSmallDevice ? "flex-start" : "center",
          mb: 2,
        })}
      >
        <Box>
          {Array.from({ length: 5 }).map((val: any, ind: number) => (
            <Skeleton
              key={ind}
              variant="rectangular"
              height={20}
              width={200}
              sx={{ mb: 1 }}
            />
          ))}
        </Box>
      </Grid2>
    </Grid2>
  );
};

export const ReviewsBottomSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width={200} height={20} sx={{ my: 4 }} />
      {Array.from({ length: 3 }).map((_, ind) => (
        <Box
          key={ind}
          sx={(theme) => ({
            display: "flex",
            borderBottom: `1px solid ${theme.palette.grey["200"]}`,
            pb: 2,
          })}
        >
          <Skeleton
            variant="rectangular"
            width={15}
            height={15}
            sx={{ m: 2 }}
          />
          <Skeleton variant="circular" width={40} height={40} sx={{ m: 2 }} />
          <Box sx={{ m: 2 }}>
            <Skeleton
              variant="rectangular"
              width={100}
              height={20}
              sx={{ mb: 2 }}
            />
            <Skeleton
              variant="rectangular"
              width={200}
              height={20}
              sx={{ mb: 2 }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
