import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { flexRowCenterCenter } from "utils/styles";
import TextField, { CustomTextField } from "components/TextField";
import search from "assets/icons/search.svg";
import close from "assets/icons/close-white.svg";
import plus from "assets/icons/add-white.svg";
import EditBusinessListing from "pages/BusinessProfiles/Modals/Bulkactions";
import {
  createLabel,
  getLabels,
  mapLabel,
  removeLabel,
} from "store/labelsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useParams } from "react-router-dom";
import { groupMapping, removeGroup } from "store/groupSlice";
import { sweetAlert } from "utils/sweetAlert";

const Filters: React.FC<{ isEdit?: boolean; onClose?: () => void }> = ({
  isEdit,
  onClose,
}) => {
  const [action, setAction] = useState({
    open: isEdit,
    action: isEdit ? "6" : "0",
  });
  const { id: location_id } = useParams();
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);
  const { listing } = useSelector((state: RootState) => state.listingDetails);
  const [selectedLabels, setSelectedLabels] = useState<any>(
    listing?.label || []
  );
  const {
    labels: initialLabels,
    error: labelsError,
    labelsAction,
  } = useSelector((state: RootState) => state.labels);
  const [labels, setLabels] = useState(initialLabels);
  const {
    groups: initialGroups,
    error: groupsError,
    groupsAction,
  } = useSelector((state: RootState) => state.groups);
  const [groups, setGroups] = useState(initialGroups);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setLabels(initialLabels || []);
  }, [initialLabels]);

  useEffect(() => {
    setGroups(initialGroups || []);
  }, [initialGroups]);

  useEffect(() => {
    setSelectedLabels(listing?.label || []);
    setSelectedGroup(listing?.group[0]);
  }, [listing]);

  useEffect(() => {
    console.log({ labelsAction });
    if (labelsAction && !labelsAction?.success)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: labelsAction?.message || `Something went wrong!`,
      });
    else if (labelsAction && labelsAction?.success)
      sweetAlert({
        title: "Success!",
        icon: "success",
        text: labelsAction?.message,
      });
  }, [labelsAction]);

  useEffect(() => {
    if (labelsError)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: labelsError || `Something went wrong!`,
      });
  }, [labelsError]);

  useEffect(() => {
    console.log({ groupsAction });
    if (groupsAction && !groupsAction.success)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: groupsAction.message,
      });
    else if (groupsAction && groupsAction.success)
      sweetAlert({
        title: "Success!",
        icon: "success",
        text: groupsAction.message,
      });
  }, [groupsAction]);

  useEffect(() => {
    if (groupsError)
      sweetAlert({
        title: "Error!",
        icon: "error",
        text: groupsError || `Something went wrong!`,
      });
  }, [groupsError]);

  useEffect(() => {
    dispatch(getLabels({}));
  }, []);

  useEffect(() => {
    if (isEdit) setAction({ open: true, action: "6" });
  }, [isEdit]);

  const handleChange = (event: any, newValue: any) => {
    if (location_id) {
      if (typeof newValue === "string" && !selectedLabels.includes(newValue))
        dispatch(createLabel({ label: newValue, location_id }));
      else if (
        newValue?.inputValue &&
        !selectedLabels.includes(newValue.inputValue)
      )
        dispatch(createLabel({ label: "", location_id }));
      else if (
        newValue &&
        !selectedLabels.some((label: any) => label.id === newValue.id)
      ) {
        if (newValue)
          dispatch(mapLabel({ label_id: newValue.id, location_id }));
      }
    }
  };

  const onRemoveLabel = async (label: any) => {
    console.log({ label });
    if (location_id) {
      await dispatch(removeLabel({ label_id: label?.id, location_id }));
    }
  };

  // const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
  //   const target = event.target as HTMLUListElement;
  //   const bottom =
  //     target.scrollHeight === target.scrollTop + target.clientHeight;

  //   if (bottom && !loading && count && count % 10 === 0) {
  //     setLabelsPagination((prevPagination) => ({
  //       page: 1,
  //       limit: prevPagination.limit + 10,
  //     }));
  //   }
  // };

  return (
    <Box width="100%">
      <Box
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.grey["200"]}`,
          py: 2,
        })}
      >
        <Typography variant="h5" sx={() => ({ fontWeight: 600, pb: 1 })}>
          Profile Group
        </Typography>
        <Typography variant="body2" color="textDisabled">
          Only one profile group can be used for adding into selected business
          profile. Chosen group name will overwrite the already existing group
          name in selected profiles
        </Typography>
        <Box py={2}>
          {selectedGroup ? (
            <Chip
              variant="filled"
              sx={(theme) => ({
                backgroundColor: "#1650CF",
                color: theme.palette.secondary.main,
              })}
              label={selectedGroup?.group_name}
              deleteIcon={
                <img
                  src={close}
                  alt="close"
                  style={{ width: 14, height: 14 }}
                />
              }
              onDelete={() => {
                if (location_id)
                  dispatch(
                    removeGroup({
                      group_id: selectedGroup?.id,
                      location_id,
                    })
                  );
              }}
            />
          ) : null}
        </Box>
        <TextField
          color="primary"
          size="small"
          variant="outlined"
          placeholder="Search"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <img src={search} alt="search" width={16} height={16} />
                </InputAdornment>
              ),
            },
          }}
          sx={{
            mb: 0,
            minWidth: "60%",
          }}
          type="search"
        />
        <Box
          gap={1}
          sx={() => ({
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            flexWrap: "wrap",
            maxWidth: "60%",
            py: 2,
          })}
        >
          {groups?.slice(0, 5)?.map((item) => (
            <Chip
              key={item.id}
              label={item.group_name}
              icon={<img src={plus} alt="plus" />}
              sx={(theme) => ({
                backgroundColor: theme.palette.grey["500"],
                color: theme.palette.secondary.main,
                fontWeight: 600,
              })}
              onClick={async () => {
                if (location_id)
                  await dispatch(
                    groupMapping({ location_id, group_id: item.id })
                  );
              }}
            />
          ))}
        </Box>
      </Box>
      <Box sx={() => ({ py: 2 })}>
        <Typography variant="h5" sx={() => ({ fontWeight: 600, pb: 1 })}>
          Filter Labels
        </Typography>
        <Typography variant="body2" color="textDisabled">
          You can create or choose multiple labels to create various levels of
          filter options
        </Typography>
        <Box py={2} gap={1} sx={{ display: "flex", flexWrap: "wrap" }}>
          {selectedLabels?.map((label: any, index: number) => (
            <Chip
              key={index}
              variant="filled"
              sx={(theme) => ({
                backgroundColor: "#1650CF",
                color: theme.palette.secondary.main,
              })}
              label={label.label_name}
              deleteIcon={
                <img
                  src={close}
                  alt="close"
                  style={{ width: 14, height: 14 }}
                />
              }
              onDelete={() => onRemoveLabel(label)}
            />
          ))}
        </Box>
        <Autocomplete
          value=""
          onChange={handleChange}
          filterOptions={(options, params) => {
            const { inputValue } = params;
            const filtered =
              options.filter((option) =>
                option.label_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) || [];
            const isExisting = options.some((option) =>
              option.label_name.toLowerCase().includes(inputValue.toLowerCase())
            );

            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                label_name: `"${inputValue}" (New Label)`,
              });
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="select-labels"
          options={labels || []}
          getOptionLabel={(option) => {
            if (typeof option === "string") return option;
            if (option.inputValue) return option.inputValue;
            return option?.label_name;
          }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option?.id}
                style={{ fontWeight: 400, fontSize: "14px" }}
              >
                {option.label_name}
              </li>
            );
          }}
          sx={{
            width: 300,
            fontWeight: 400,
            fontSize: "14px",
            minWidth: "60%",
          }}
          freeSolo
          size="small"
          renderInput={(props) => (
            <CustomTextField
              sx={{ p: 0 }}
              {...props}
              variant="outlined"
              placeholder="Search Labels"
              slotProps={{
                input: {
                  ...props.InputProps,
                  endAdornment: (
                    <>
                      {props.InputProps.endAdornment}
                      <img
                        src={search}
                        alt="search"
                        style={{ width: 20, height: 20 }}
                      />
                    </>
                  ),
                },
              }}
            />
          )}
          // ListboxProps={{
          //   onScroll: handleScroll,
          // }}
        />
      </Box>
      <Modal
        open={action.open || (isEdit && action.open ? true : false)}
        onClose={() => {
          setAction({ action: "0", open: false });
          if (isEdit && onClose) onClose();
        }}
      >
        <EditBusinessListing
          setActionOrOpen={({ action = "0", open = true }) => {
            setAction({ action, open });
            if (onClose) onClose();
          }}
          action={action.action}
        />
      </Modal>
    </Box>
  );
};

export default Filters;
