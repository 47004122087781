import {
  Box,
  Grid2,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  GlobalStyles,
  Divider,
  Rating,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import styled from "@emotion/styled";

import dropdown from "assets/icons/dropdown.svg";
import desktop from "assets/icons/desktop.svg";
import mobile from "assets/icons/mobile.svg";
import tablet from "assets/icons/tablet.svg";
import google from "assets/icons/google.svg";
import ArrowLongUp from "assets/icons/arrowLongUp";
import { flexRowCenterCenter } from "utils/styles";
import CustomDateRangePicker from "components/DateRangePicker";

type DetailsComponentProps = {
  label: string;
  value: string;
  descriptionElement: React.ReactNode | string;
};

export const DetailsComponent: React.FC<DetailsComponentProps> = ({
  label,
  value,
  descriptionElement,
}) => {
  return (
    <Box sx={{ textAlign: "left" }}>
      <Typography color="primary" variant="h5" pb={2}>
        {label}
      </Typography>
      <Typography color="primary" variant="h2">
        {value}
      </Typography>
      {typeof descriptionElement === "string" ? (
        <Typography
          variant="body2"
          color="textDisabled"
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "flex-start",
            pb: 2,
          }}
        >
          {descriptionElement}
        </Typography>
      ) : (
        <Box pb={2}>{descriptionElement}</Box>
      )}
    </Box>
  );
};

type ColorCardProps = {
  color: string;
  textColor: string;
  title: string;
  value: string;
  increment: string;
};

export const ColorCard: React.FC = () => {
  const colorCardData: ColorCardProps[] = [
    {
      color: "#EBF1FD",
      textColor: "#1442A7",
      title: "Direct",
      value: "40%",
      increment: "20%",
    },
    {
      color: "#E8F6ED",
      textColor: "#137A5F",
      title: "Organic",
      value: "30%",
      increment: "5%",
    },
    {
      color: "#FFF8F1",
      textColor: "#D03801",
      title: "Social",
      value: "25%",
      increment: "15%",
    },
    {
      color: "#ECEFF1",
      textColor: "#18181C",
      title: "Others",
      value: "5%",
      increment: "2%",
    },
  ];
  return (
    <Box
      gap={1}
      sx={{
        width: "calc(50% - 16px)",
        minWidth: 350,
        ...flexRowCenterCenter,
        flexWrap: "wrap",
        flexGrow: 1,
        pl: 3,
        pr: 2,
        boxSizing: "border-box",
      }}
    >
      {colorCardData.map((colorCard, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: colorCard.color,
            py: 3,
            px: 2,
            width: "calc(50% - 8px)",
            flexGrow: 1,
            textAlign: "left",
            boxSizing: "border-box",
          }}
        >
          <Typography
            variant="body1"
            sx={(theme) => ({ color: theme.palette.grey["600"] })}
          >
            {colorCard.title}
          </Typography>
          <Typography variant="h3" color="primary" sx={{ pb: 1.5 }}>
            {colorCard.value}
          </Typography>
          <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
            <ArrowLongUp color={colorCard.textColor} />
            <Typography
              variant="body2"
              sx={{ color: colorCard.textColor, fontWeight: 600 }}
            >
              {colorCard.increment}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type CardComponentProps = { content: React.ReactNode };

export const CardComponent: React.FC<CardComponentProps> = ({ content }) => (
  <Grid2
    size={{ xs: 12, md: 6, lg: 4 }}
    sx={{
      p: 3,
      border: "1px solid #dbdbdb",
      borderRadius: "8px",
    }}
  >
    {content}
  </Grid2>
);

type DataItem = {
  name: string;
  value: number;
};

export const BarChartComponent = () => {
  const data: DataItem[] = [
    { name: "Jan", value: 2.4 },
    { name: "Feb", value: 2.8 },
    { name: "Mar", value: 2.7 },
    { name: "Apr", value: 2.2 },
    { name: "May", value: 3.6 },
  ];
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={data}
        margin={{ top: 30, right: 0, left: -15, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="10 5" vertical={false} />
        <XAxis dataKey="name" axisLine={false} tickLine={false} />
        <YAxis
          domain={[0, 4]}
          interval={1}
          tickFormatter={(value) => `${value} M`}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip />
        <Bar
          dataKey="value"
          fill="#8fa3fa"
          barSize={40}
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

type LinearProgressDataProps = {
  icon: string;
  title: string;
  value: string;
  progress: number;
  color: string;
};

type BorderLinearProgressProps = { bgcolor: string };

export const BorderLinearProgress = styled(
  LinearProgress
)<BorderLinearProgressProps>(({ bgcolor }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D1D1D1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: bgcolor,
  },
}));

const LinearProgressCard = () => {
  const LinearProgressData: LinearProgressDataProps[] = [
    {
      icon: desktop,
      title: "Desktop",
      value: "4,089/ 76%",
      progress: 76,
      color: "#8EA2FA",
    },
    {
      icon: mobile,
      title: "Mobile",
      value: "1,149/ 21%",
      progress: 21,
      color: "#1FC3D0",
    },
    {
      icon: tablet,
      title: "Tablet",
      value: "150/ 3%",
      progress: 3,
      color: "#FFC565",
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "calc(50% - 16px)",
        minWidth: 350,
        boxSizing: "border-box",
        pr: 3,
        pl: 3,
      }}
    >
      {LinearProgressData?.map((item, index) => (
        <Box key={index} pb={5}>
          <Box
            sx={{
              ...flexRowCenterCenter,
              justifyContent: "space-between",
              pb: 1,
            }}
          >
            <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
              <img src={item?.icon} alt={item?.icon} />
              <Typography variant="h5" sx={{ pl: 1 }}>
                {item?.title}
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ color: "#646464" }}>
              {item?.value}
            </Typography>
          </Box>
          <BorderLinearProgress
            variant="determinate"
            value={item?.progress}
            bgcolor={item?.color}
          />
        </Box>
      ))}
      <Typography variant="body2" sx={{ color: "#686868", textAlign: "left" }}>
        Increased in the last 7 days especially on Mobile
      </Typography>
    </Box>
  );
};

type SearchAnalyticsProps = {
  size?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
};

export const SearchAnalyticsCard: React.FC<SearchAnalyticsProps> = ({
  size,
}) => {
  return (
    <Grid2
      size={size ? size : { xs: 12, md: 12, lg: 8 }}
      sx={{
        border: "1px solid #dbdbdb",
        borderRadius: "8px",
        pb: 3,
      }}
    >
      <Box
        gap={1}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
          p: 3,
          pb: 2,
        }}
      >
        <Typography color="primary" variant="h5" sx={{ whiteSpace: "nowrap" }}>
          Search Analytics
        </Typography>
        <Box
          gap={1}
          sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}
        >
          <CustomDateRangePicker />
          <img src={dropdown} alt="dropdown" />
        </Box>
      </Box>
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <ColorCard />
        <LinearProgressCard />
      </Box>
    </Grid2>
  );
};

interface KeywordData {
  keyword: string;
  volume: number;
  kd: number;
}

const tableData: KeywordData[] = [
  { keyword: "Nike", volume: 9600, kd: 75 },
  { keyword: "Nike slippers", volume: 8250, kd: 44 },
  { keyword: "Nike showroom", volume: 6120, kd: 40 },
  { keyword: "Nike showroom near me", volume: 5432, kd: 39 },
  { keyword: "Nike Products", volume: 1800, kd: 12 },
];

export const KeywordTable: React.FC = () => {
  return (
    <TableContainer
      sx={{
        "& .MuiTableCell-root": { p: 1 },
      }}
    >
      <Table aria-label="keyword table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={(theme) => ({
                color: theme.palette.grey["900"],
                fontWeight: 600,
              })}
            >
              Keywords
            </TableCell>
            <TableCell
              sx={(theme) => ({
                color: theme.palette.grey["900"],
                fontWeight: 600,
              })}
              align="right"
            >
              Volume
            </TableCell>
            <TableCell
              sx={(theme) => ({
                color: theme.palette.grey["900"],
                fontWeight: 600,
              })}
              align="right"
            >
              KD%
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => {
            return (
              <TableRow key={row.keyword}>
                <TableCell
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                  })}
                  component="th"
                  scope="row"
                >
                  {row.keyword}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                  })}
                  align="right"
                >
                  {row.volume.toLocaleString()}
                </TableCell>
                <TableCell
                  sx={(theme) => ({
                    color: theme.palette.text.disabled,
                  })}
                  align="right"
                >
                  {row.kd}%
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const viewsData = [
  { name: "Map views", value: 30, color: "#8C9EFF" },
  { name: "Search views", value: 10, color: "#26C6DA" },
];

export const GaugeChart: React.FC = () => {
  return (
    <div style={{ width: "100%", height: 250 }}>
      <GlobalStyles
        styles={{
          ".recharts-sector": { borderRadius: "8px !important" },
          ".recharts-pie": {
            top: "100px !important",
            position: "relative !important",
          },
        }}
      />
      <ResponsiveContainer width="100%" height={400}>
        <PieChart margin={{ top: -10, right: 30, left: 20, bottom: 5 }}>
          <Pie
            data={viewsData}
            startAngle={180}
            endAngle={0}
            innerRadius="60%"
            outerRadius="80%"
            dataKey="value"
            labelLine={false}
            style={{ top: 10, position: "relative" }}
          >
            {viewsData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Legend
            iconType="square"
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            formatter={(value) => (
              <span>
                <Typography color="textDisabled" style={{ display: "inline" }}>
                  {value}
                </Typography>
                <Typography variant="h5" color="primary">
                  {value === "Map views" ? "30k" : "10k+"}
                </Typography>
              </span>
            )}
            wrapperStyle={{ bottom: 160 }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export const ActivitiesCard: React.FC = () => {
  const data = [
    {
      name: "Jan",
      "Direction requests": 3200000,
      "Phone calls": 2000000,
      "Website visits": 1000000,
    },
    {
      name: "Feb",
      "Direction requests": 3000000,
      "Phone calls": 2200000,
      "Website visits": 1200000,
    },
    {
      name: "Mar",
      "Direction requests": 4000000,
      "Phone calls": 2400000,
      "Website visits": 1300000,
    },
    {
      name: "Apr",
      "Direction requests": 2500000,
      "Phone calls": 1800000,
      "Website visits": 1100000,
    },
    {
      name: "May",
      "Direction requests": 2800000,
      "Phone calls": 2000000,
      "Website visits": 900000,
    },
  ];
  return (
    <CardComponent
      content={
        <Box>
          <Typography variant="h5" sx={{ pb: 6, textAlign: "left" }}>
            Activities
          </Typography>
          <ResponsiveContainer width="100%" height={240}>
            <BarChart
              data={data}
              margin={{
                top: 20,
                right: 5,
                left: -10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="10 5" vertical={false} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) => `${value / 1000000} M`}
                interval={1}
              />
              <Tooltip />
              <Legend
                iconType="square"
                align="left"
                wrapperStyle={{
                  bottom: "-10px",
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "24px",
                }}
                formatter={(value) => (
                  <span>
                    <Typography
                      color="textDisabled"
                      style={{ display: "inline" }}
                    >
                      {value}
                    </Typography>
                    <Typography
                      variant="h5"
                      color="textPrimary"
                      style={{ textAlign: "left", paddingBottom: "8px" }}
                    >
                      {value === "Phone calls"
                        ? "24.2k"
                        : value === "Direction requests"
                          ? "320k"
                          : "80k"}
                    </Typography>
                  </span>
                )}
              />
              <Bar
                dataKey="Phone calls"
                fill="#95E0BA"
                barSize={12}
                radius={[4, 4, 0, 0]}
              />
              <Bar
                dataKey="Direction requests"
                fill="#8FA3FA"
                barSize={12}
                radius={[4, 4, 0, 0]}
              />
              <Bar
                dataKey="Website visits"
                fill="#F2B9AD"
                barSize={12}
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      }
    />
  );
};

export const DiscoveryCard: React.FC = () => {
  const data = [
    { name: "Jan", Views: 100000, Activity: 50000 },
    { name: "Feb", Views: 120000, Activity: 80000 },
    { name: "Mar", Views: 90000, Activity: 60000 },
    { name: "Apr", Views: 110000, Activity: 70000 },
    { name: "May", Views: 105000, Activity: 65000 },
    { name: "Jun", Views: 115000, Activity: 85000 },
    { name: "Jul", Views: 95000, Activity: 50000 },
  ];
  // const maxValue = Math.ceil(Math.max(...data.map(d => d.Activity)) / 50000) * 50000;
  // const ticks = Array.from({ length: Math.floor(maxValue / 50000) + 1 }, (_, i) => i * 50000);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: -10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="5 10" vertical={false} />
        <XAxis
          dataKey="name"
          axisLine={{ stroke: "#DBDBDB", strokeWidth: 1 }}
          tickLine={false}
        />
        <YAxis
          tickFormatter={(value) => `${value / 1000}k`}
          ticks={[0, 60000, 120000]}
          axisLine={{ stroke: "#DBDBDB", strokeWidth: 1 }}
          tickLine={false}
        />
        {/* <Tooltip /> */}
        <Legend
          iconType="circle"
          layout="horizontal"
          verticalAlign="top"
          align="left"
          style={{ bottom: 30 }}
          formatter={(value) => (
            <span>
              <Typography color="textDisabled" style={{ display: "inline" }}>
                {value}
              </Typography>
              <Typography
                variant="h5"
                color="primary"
                style={{
                  textAlign: "left",
                  marginLeft: "20px",
                  marginBottom: "30px",
                  left: 20,
                }}
              >
                {value === "Views" ? "4.0M" : "120.4k"}
              </Typography>
            </span>
          )}
        />
        <Bar
          dataKey="Views"
          fill="#8DA3FE"
          radius={[4, 4, 0, 0]}
          barSize={20}
        />
        <Bar
          dataKey="Activity"
          fill="#CBCBCB"
          radius={[4, 4, 0, 0]}
          barSize={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export const CallsComponent: React.FC = () => {
  const data = [
    { name: "Calls answered", value: 240, color: "#95E0BA" },
    { name: "Calls Ignored", value: 80, color: "#ED727C" },
    { name: "Total Calls", value: 320, color: "#8D9CAD" },
  ];

  const COLORS = data.map((entry) => entry.color);
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #DBDBDB",
        p: "32px 24px",
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">Calls</Typography>
        <img src={dropdown} alt="dropdown" />
      </Box>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="40%"
            outerRadius="80%"
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
            }}
            layout="horizontal"
            verticalAlign="bottom"
            align="left"
            formatter={(value, entry, index) => (
              <span>
                <Typography color="textDisabled" style={{ display: "inline" }}>
                  {value}
                </Typography>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ textAlign: "left", paddingBottom: "8px" }}
                >
                  {data[index].value}
                </Typography>
              </span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const ChatsComponent: React.FC = () => {
  const chatsData = [
    { title: "Total Chats", value: "1200", percentageChange: 5 },
    { title: "Unread", value: "405", percentageChange: 5 },
    { title: "Average response time", value: "1hr 30m", percentageChange: 5 },
  ];
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #DBDBDB",
        p: "32px 24px",
        flexGrow: 1,
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">Chats</Typography>
        <img src={dropdown} alt="dropdown" />
      </Box>
      {chatsData?.map((data, index) => (
        <Box
          key={index}
          sx={{
            ...flexRowCenterCenter,
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="body2" color="textDisabled">
              {data?.title}
            </Typography>
            <Typography variant="h3" color="textPrimary">
              {data?.value}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            fontWeight={600}
            color={data?.percentageChange >= 0 ? "success" : "error"}
          >
            {data?.percentageChange}%
          </Typography>
        </Box>
      ))}
      <Divider sx={{ mb: 3 }} />
      <BorderLinearProgress
        variant="determinate"
        value={80}
        bgcolor="#4285F4"
      />
      <Box
        sx={{ ...flexRowCenterCenter, justifyContent: "space-between", mt: 1 }}
      >
        <Box sx={{ ...flexRowCenterCenter, justifyContent: "flex-start" }}>
          <img src={google} alt="google" />
          <Typography
            variant="body2"
            color="textDisabled"
            sx={{ display: "inline", ml: 1 }}
          >
            Google
          </Typography>
        </Box>
        <Typography variant="h5">800</Typography>
      </Box>
    </Box>
  );
};

export const RatingsComponent: React.FC<{ showDivider?: boolean }> = ({
  showDivider = true,
}) => {
  const data = [
    { title: "High Performing", value: 120 },
    { title: "Avg Performing", value: 80 },
    { title: "Low Performing", value: 30 },
  ];
  return (
    <>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5">Ratings & Reviews</Typography>
        <img src={dropdown} alt="dropdown" />
      </Box>
      <Box
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="body2">Overall Ratings</Typography>
          <Typography variant="h5">4.5</Typography>
        </Box>
        <Rating
          name="read-only"
          size="large"
          value={4.5}
          precision={0.5}
          readOnly
        />
      </Box>
      {showDivider && <Divider sx={{ mb: 2 }} />}
      <Box
        gap={2}
        sx={{
          ...flexRowCenterCenter,
          justifyContent: "flex-start",
          flexWrap: "wrap",
        }}
      >
        {data?.map((item, index) => (
          <Box key={index} sx={{ textAlign: "left" }}>
            <Typography color="textDisabled" variant="body2">
              {item?.title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h5"
              sx={{ display: "inline" }}
            >
              {item?.value}
            </Typography>{" "}
            <Typography
              color="textDisabled"
              sx={{ display: "inline" }}
              variant="body2"
            >
              Store
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};
